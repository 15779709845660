<template>
  <div>
    <Nav :name="name" />
    <section id="home">
      <div id="presentation">
          <div class="text_presentation">
              <p> {{text_desc}}</p>
              <img :src="logoSrc" :style="{ width: logoWidth }" alt="logo">
          </div>
      </div>

      <div class="contact">
            <p>Pour me contacter : </p>
      </div>

      <div class="git_linkedin">
        <a class="git_linkedin_co" href="https://github.com/Gautias" target="_blank" rel="noopener">
            <img src="../assets/images/github.png" width="40px" height="40px" alt="logo_github">
        </a>
        <a href="https://www.linkedin.com/in/valentin-gaultier-8766b7199/" target="_blank" rel="noopener">
            <img src="../assets/images/linkedin.png" width="40px" height="40px" alt="logo_linkedin">
        </a>

        <a class="git_linkedin_co"  href="mailto:valentin.gaultier147@orange.fr" target="_blank" rel="noopener">
            <img src="../assets/images/email.png" width="40px" height="40px" alt="logo_linkedin">
        </a>

      </div>
      
  
      <svg viewBox="0 120 1440 140" style="transform: translateY(25px); position: relative; z-index: -1;">
            <path fill="#ecf0f1" fill-opacity="1" d="M0,192L48,197.3C96,203,192,213,288,229.3C384,245,480,267,576,250.7C672,235,768,181,864,181.3C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
    </section>

    
    <section id="services">
      <div class="heading">
        <h3>{{title_presta_site}}</h3>
        <div class="presta_interline"></div>
        <p class="desc_card_site">{{desc_presta_site}}</p>
      </div>
      <div id="presta">
        <div class="card" v-for="prestation in prestations" :key="prestation.id">
          <img :src="prestation.imageSrc" alt="Image de la prestation">
          <h2>{{ prestation.title }}</h2>
          <p>{{ prestation.description }}</p>
        </div>
      </div>
            <svg viewBox="0 64 1440 120" style="transform: translateY(5px);">
                <path fill="white" fill-opacity="1" d="M0,64L48,74.7C96,85,192,107,288,106.7C384,107,480,85,576,96C672,107,768,149,864,165.3C960,181,1056,171,1152,154.7C1248,139,1344,117,1392,106.7L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
            </svg>
    </section>


    <!-- card -->

    <section id="site">
        <div class="container">
            <div class="heading">
                <h3>{{title_card_site}}</h3>
                <div class="interline"></div>
                <p class="desc_card_site">{{desc_card_site}}</p>
            </div>
            <ul>
                <li id="card" v-for="(item, index) in items" :key="index" @click="redirectToLink(item.link)" :style="{ backgroundImage: `url(${item.image})` }"></li>
            </ul>
       </div>
    </section>

    <Footer />

  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";

export default {
  components:{
    Nav,
    Footer,
  },
  methods:{
    redirectToLink(link){
      window.location.href =  link;
    }
  },
  data() {
    return {
      name: "Concept Génération",
      text_desc: "Découvrez Valentin Gaultier, développeur web spécialisé en applications web et mobiles, formateur. De la création de vitrines séduisantes à des sites e-commerce performants, il vous guide dans le monde numérique pour donner vie à vos projets ! ",
      logoSrc: "https://image.noelshack.com/fichiers/2023/29/6/1690010044-logo-moi.png",
      logoWidth: "40%",



      title_presta_site:"Nous concevons des sites et des applications conçus pour perdurer.",
      desc_presta_site: "L'innovation numérique prend son envol chez Concept-Génération grâce à notre moteur essentiel : une expertise complète des technologies du Web, incluant HTML, CSS, JavaScript, langages clients et serveurs, socles techniques, frameworks et architectures.",

      prestations:[
        {
        id: 1,
        title: 'Sites de communication',
        description: "Plongez dans le monde de la communication web, où les sites s'épanouissent grâce à des socles CMS (Content Management System) ou SGC (Système de Gestion de Contenus).",
        imageSrc: 'https://image.noelshack.com/fichiers/2023/30/2/1690276108-icon-g26b71add8-640-removebg-preview.png'
        },        
        {
        id: 2,
        title: 'Sites e-commerce',
        description: "Embarquez vers le succès du e-commerce avec Concept-Génération ! Nos sites s'appuient sur des socles Open Source tels que Prestashop, permettant une personnalisation sur-mesure pour répondre à vos besoins spécifiques.",
        imageSrc: 'https://image.noelshack.com/fichiers/2023/30/2/1690271039-cart-gf9022b862-640-removebg-preview.png'
        },
        {
        id: 3,
        title: 'Applications mobiles',
        description: "Créez des liens puissants avec vos utilisateurs grâce à nos applications mobiles ! Conçues pour Android et iOS, nos applications respectent les principes ergonomiques propres à chaque plateforme, assurant ainsi une expérience optimale pour tous.",
        imageSrc: 'https://image.noelshack.com/fichiers/2023/30/2/1690291545-icon-gaf1a37a9e-640-removebg-preview.png'
        },
      ],

      // itemps : 
      title_card_site: "Les sites réalisés ",
      desc_card_site: "Ici vous pouvez voir une grande partie de mon travail !",
      items:[
        // { link: 'https://ateliergraphique36.fr/', image: 'https://image.noelshack.com/fichiers/2023/28/5/1689326424-texte6-page-0001.jpg' },
        { link: 'https://innolive.fr/', image: 'https://image.noelshack.com/fichiers/2023/28/5/1689326331-logosignature-e1670575144338.png' },
        { link: 'https://mon-epicerie-berrichonne.fr/', image: 'https://image.noelshack.com/fichiers/2023/28/5/1689326541-cropped-logo-magasin.png' },
        { link: 'https://aletat-naturel.fr/', image: 'https://image.noelshack.com/fichiers/2023/47/2/1700584106-aletat-fotor-20231121172750.png' },
      ],

    };
  },
};
</script>

<style>

@import url(../assets/css/index.css);
@import url(../assets/css/card.css);
@import url(../assets/css/presta.css);



/* Styles spécifiques au composant Vue */
</style>
